import {
  LOGOUT,
  GET_MY_USER,
  VERIFY_AUTH,
} from '@/core/services/store/auth.module';
import JwtService from '@/core/services/jwt.service';

export default function verifyUser({ next, store, router }) {
  const myUser = JwtService.getCurrentUserToken();
  if (myUser) {
    store.dispatch(GET_MY_USER,myUser)
  } else {
    store.dispatch(LOGOUT).then(() => (window.location = '/giris'));
  }

  return next();
}